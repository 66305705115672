<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div v-if="!loading" class="text-center">
        <div class="text-h5 ma-7" v-if="confirmed">
          Ваша учётная запись успешно подтверждена. Нажмите продолжить чтобы
          выполнить вход.
          <br />
          <v-btn color="primary" x-large to="/" class="ma-7">Продолжить</v-btn>
        </div>
        <div v-else class="text-h5 ma-7">
          Возникла ошибка при выполнение действия <br />
          <div class="red--text lighten-3 fornt-weight-bold">{{ error }}</div>
          <v-btn color="primary" x-large to="/" class="ma-7">Продолжить</v-btn>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      confirmed: false,
      error: "",
    };
  },
  mounted() {
    this.loading = true;
    this.$http
      .get("auth/confirm", {
        params: {
          userId: this.$route.params.userId,
          token: this.$route.params.token,
        },
      })
      .then(() => {
        this.confirmed = true;
      })
      .catch((e) => {
        this.confirmed = false;
        if (e.response.data.error) {
          this.error = e.response.data.error;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style>
</style>